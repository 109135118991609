/**
 * Theme Name: G&G theme
 *
 * Author:Kiwi adv
 * Author URI:http://www.kiwiadv.com
 * Author e-mail:info@kiwiadv.com
 * Version: 2.0.5
 * File Description: Place here your custom CSS styles
*/
#logo_img {
  height: 70px;
}
a:hover {
	text-decoration: none;
}
ul.footer-menu {
  list-style: none;
  -webkit-padding-start: inherit;
}
ul.footer-menu > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
}
h1.e404 {
  font-size: 130px!important;
  margin-bottom: 60px!important;
}
h2.e404 {
	font-weight: 300;
}
.fixed-header-on .header-dropdown-buttons {
  padding-top: 21px;
}
.wpcf7-form-control.g-recaptcha.wpcf7-recaptcha {
  border: none;
  box-shadow: none;
}
.wpcf7-form-control-wrap {
  min-height: 140px;
}
table.cookie-table {
  width: 100%!important;
}
.gallery {
  margin: auto;
  display: inline-block;
}
.gallery .gallery-item {
  float: left;
  margin-top: 10px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}
.gallery img {
  border: 1px solid #cfcfcf;
  padding: 3px;
}
.gallery .gallery-caption {
  margin-left: 0;
}
.dropdown-menu {
  margin-top: 0;
}
.dropdown-menu.show {
  border-right: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
}
.excerpt-work {
  min-height: 50px;
}
h4.title-work {
  min-height: 55px;
}
img.portfolio-image {
  min-height: 250px;
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .main h1 {
    font-size: 150%!important;
  }
  .banner {
    display: none;
  }
  .header-dropdown-buttons .btn-group .dropdown-menu {
    margin-top: 15px;
  }
}